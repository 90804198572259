<template>
  <div>
    <v-container
      fluid
      class="mb-9"
    >
      <v-row>
        <v-col>
          <div class="display-flex align-items-center">
            <p class="text-title mon-bold">{{ texts.warehouses.textTitle }}</p>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isResponsive"
              @click="addWarehouses"
              elevation="0"
              class="button-primary"
              icon
              width="40px !important"
              height="40px !important"
            >
              <v-icon size="18px">mdi-plus-thick</v-icon>
            </v-btn>
            <v-btn
              v-else
              @click="addWarehouses"
              elevation="0"
              class="button-add mon-bold"
            >
              {{ texts.warehouses.textButtonAdd }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="aWarehouses.length > 0">
        <v-col
          cols="12"
          xl="3"
          lg="4"
          md="6"
          sm="6"
          v-for="(item, index) in aWarehouses"
          :key="index"
        >
          <div class="container-card mr-5">
            <div>
              <p class="text-warehouse mon-medium">{{ item.oWarehouse.sName }}</p>
              <p
                :style="{ height: `${restantHeight}px` }"
                class="text-address mon-regular"
              >
                {{ 
                (item.oLocation.sLocationOptionalAddress == null || item.oLocation.sLocationOptionalAddress == '')
                ? item.oLocation.sAddress + ', ' + item.oLocation.sCity + ', ' + item.oLocation.sStateName
                : item.oLocation.sAddress + ', ' + item.oLocation.sLocationOptionalAddress + ', ' + item.oLocation.sCity + ', ' + item.oLocation.sStateName
               }}
              </p>
              <div>
                <p class="text-phone mon-regular">
                  <v-icon size="16px">
                    mdi-phone-outline
                  </v-icon>
                  {{ item.oWarehouse.sPhoneNumber}}
                </p>
              </div>
            </div>
            <div
              v-if="isResponsive"
              class="display-flex align-items-center justify-content-flex-end"
            >
              <v-btn
                :to="`/warehouse/${item.sVendorWarehouseId}`"
                elevation="0"
                class="button-primary mon-bold"
                style="width: 100%;"
              >
                {{ texts.warehouses.textButtonView }}
              </v-btn>
            </div>
            <div
              v-else
              class="display-flex align-items-center justify-content-flex-end"
            >
              <v-btn
                :to="`/warehouse/${item.sVendorWarehouseId}`"
                elevation="0"
                class="button-primary mon-bold"
              >
                {{ texts.warehouses.textButtonView }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <div
            style="height: 500px;"
            class="card-content display-flex align-items-center justify-content-center"
          >
            <p class="empty-text mon-regular">{{ texts.warehouses.textEmpty }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div
      v-if="aWarehouses.length < 4"
      style="height: 260px;"
    />
    <div
      v-if="aWarehouses.length >= 4 && aWarehouses.length <= 6"
      style="height: 100px;"
    />
  </div>
</template>

<script>
export default {
  name: "WarehousesContentLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      aWarehouses: [],
      restantHeight: 0,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getWarehouses();
  },
  updated() {
    this.resizeDiv();
  },
  created() {
    window.addEventListener("resize", this.resizeDiv);
    this.resizeDiv();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeDiv);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    resizeDiv: function () {
      var contentHeight = document.getElementsByClassName("text-address");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeight = max;
    },
    addWarehouses: function () {
      this.$store.commit("setDialogAddWarehouse", true);
    },
    redirectWarehouses: function () {
      this.$router.push("/warehouses");
    },
    getWarehouses: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/warehouses`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.aWarehouses = response.data.results;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      this.getWarehouses();
    },
  },
};
</script>

<style scoped>
.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.text-warehouse {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-address {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 5px;
}

.text-phone {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 5px;
}

.button-view-detail {
  background-color: #ffffff !important;
  border: 1px solid #283c4d;
  border-radius: 13px;
  opacity: 1;
  height: 45px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  text-transform: initial;
}

.container-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  border-radius: 9px;
  width: 100%;
}

.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  height: 40px !important;
  text-transform: initial;
}

.text-title {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}
</style>